<template>
  <div class="rule" v-if="teamPlate_status">
    <!--  标签页-->
    <el-tabs v-model="activeName" class="el-nav" @tab-click="handleClick">
      <!-- <el-tab-pane :label="num.article > 9999 ? '文章 ' + (num.article / 10000).toFixed(2) + 'W' : '文章 ' + num.article" name="first"> </el-tab-pane>
      <el-tab-pane :label="num.video > 9999 ? '视频 ' + (num.video / 10000).toFixed(2) + 'W' : '视频 ' + num.video" name="two"> </el-tab-pane> -->
      <el-tab-pane v-if="is_admin_middleground === 'true'" :label="num.course > 9999 ? '课程 ' + (num.course / 10000).toFixed(2) + 'W' : '课程 ' + num.course" name="three"> </el-tab-pane>
      <el-tab-pane v-if="is_admin_middleground === 'true'" :label="num.replay > 9999 ? '直播回放 ' + (num.replay / 10000).toFixed(2) + 'W' : '直播回放 ' + num.replay" name="four">
      </el-tab-pane>
    </el-tabs>

    <!--        搜索查询-->
    <div class="search space-between" style="margin: 15px 0 30px 0">
      <div class="flex-nowrap top-list">
        关键词
        <el-input v-model="keywords" placeholder="请输入查询条件" class="search-input"></el-input>
        <el-button type="primary" class="search-btn" @click="screenSubmit()">查询</el-button>
        <div class="total-right">
          评论排序
          <el-select v-model="sort" placeholder="请选择" class="search-type">
            <el-option v-for="item in optionsTime" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          状态
          <el-select v-model="status" placeholder="请选择" class="search-type">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-button type="primary" class="search-btn" @click="commentQuery">查询</el-button>
          <el-button class="search-btn reset" @click="resetFun">重置</el-button>
        </div>
      </div>
    </div>
    <!--评论-->
    <div class="comment flex-nowrap" v-loading="loading">
      <div class="comment-left" v-if="creationData.meta && creationData.meta.total !== 0">
        <div class="total-left">
          共 <span class="total-left-text">{{ creationData.meta && creationData.meta.total > 9999 ? (creationData.meta.total / 10000).toFixed(2) + 'W' : creationData.meta.total }}</span
          ><span v-text="activeName === 'first' ? ' 篇文章' : activeName === 'two' ? ' 个视频' : activeName === 'three' ? ' 个课程' : ' 个回播'"></span>
        </div>
        <div class="">
          <div class="comment-list-item" v-for="(item, index) in creationData.data" :key="index" :class="active === index ? 'active' : ''" @click="activeItem(item, index)">
            <div class="comment-list-item-title white-space">
              {{ item.title }}
            </div>
            <div class="space-between vertical-center">
              <div class="vertical-center">
                <div class="comment-list-item-name white-space">
                  {{ item.author || (item.created_user && item.created_user.nickname) }}
                  {{ item.user && item.user.nickname }}
                </div>
                <div class="" v-if="item.pending_comments_count && item.pending_comments_count !== 0" style="color: #81d8d0; font-size: 12px; margin-left: 5px">
                  {{ item.pending_comments_count }}条待筛选
                </div>
              </div>
              <div class="comment-list-item-time">
                {{ item.created_at_humans }}
              </div>
            </div>
          </div>
        </div>
        <!--  分页  -->
        <el-pagination
          v-if="creationData.meta && creationData.meta.last_page > 1"
          class="page"
          background
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          :pager-count="5"
          layout="prev, pager, next"
          :total="creationData.meta && creationData.meta.total"
        >
        </el-pagination>
      </div>
      <div class="comment-left align-center" v-else>
        <div class="" style="color: #93999f">暂无相关数据</div>
      </div>
      <div class="comment-right">
        <div class="total space-between vertical-center">
          <div class="total-left">
            全部评论
            <span class="total-left-text1">
              （<span class="total-left-text">{{ comments.meta ? comments.meta.total : 0 }}</span
              >条）</span
            >
          </div>
        </div>
        <div class="comment-list-div">
          <Comment :comment="comments.data" :uuid="uuid" :type="commentType" :isreply="isReply" @commentsHandle="commentsChildren" style="margin-right: 10px"></Comment>
          <div class="" v-if="comments.data && comments.data.length">
            <div class="align-center load" @click="commentMore()" v-if="comments.meta && comments.meta.last_page !== commentPage">
              <img src="~assets/images/index/more.png" alt="" />
              <span class="loadmore">加载更多</span>
            </div>
            <div class="align-center" v-else>
              <span class="loadmore">暂无更多</span>
            </div>
          </div>
          <empty-prompt style="margin-top: 100px" v-else></empty-prompt>
        </div>
      </div>
    </div>

    <!--    新增修改弹框-->
    <el-dialog class="dialog" title="回复评价" :visible.sync="isDialogVisible" top="calc( 50vh - 150px )" width="540px" :destroy-on-close="true">
      <!--  表单-->

      <el-input type="textarea" :rows="4" placeholder="请输入回复内容" v-model="name"> </el-input>

      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="isDialogVisible = false">取 消</el-button>
        <el-button class="form-operation-item" round type="primary">确 定</el-button>
      </span>
    </el-dialog>
    <!--    删除弹框-->
    <el-dialog class="delDiolog" :visible.sync="isdelDialogVisible" top="calc( 50vh - 220px )" icon="el-icon-warning" :destroy-on-close="true" width="360px" center>
      <div class="dioTitle"><i class="el-icon-warning"></i> 这是您操作比较重要的数据</div>
      <span>你确定要删除该评价么？</span>
      <div class="delDiolog-item">
        <el-radio v-model="name" label="1">垃圾广告</el-radio>
      </div>
      <div class="delDiolog-item">
        <el-radio v-model="name" label="1">不实信息</el-radio>
      </div>
      <div class="delDiolog-item">
        <el-radio v-model="name" label="1">辱骂、人身攻击等行为</el-radio>
      </div>
      <div class="delDiolog-item">
        <el-radio v-model="name" label="1">其他原因</el-radio>
      </div>
      <el-input type="textarea" :rows="2" placeholder="请输入回复内容" v-model="name"> </el-input>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="isdelDialogVisible = false">取 消</el-button>
        <el-button class="form-operation-item" round type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Comment from 'components/comment';
import EmptyPrompt from 'components/EmptyPrompt';
export default {
  name: 'Index',
  components: {
    Comment,
    EmptyPrompt,
  },
  data() {
    return {
      teamPlate_status: false,
      isEltab: 1,
      isAdd: true,
      name: '',
      value: '',
      loading: false,
      //评论
      isDialogVisible: false,
      //点赞
      isdelDialogVisible: false,
      value1: '',
      activeName: 'three',
      input: '',
      //状态
      options: [
        {
          value: 0,
          label: '待审核',
        },
        {
          value: 1,
          label: '公开',
        },
      ],
      //排序
      optionsTime: [
        {
          value: 1,
          label: '倒序',
        },
        {
          value: 2,
          label: '升序',
        },
      ],
      creationData: {}, // 创作接口数据
      page: 1, // 当前页码
      pageSize: 7, // 当前数据
      type: '3', //类型
      keywords: '', //关键词
      comments: '', //评论列表
      uuid: '', //uuid
      commentType: '', //评论类型
      status: '', //状态
      sort: 1, //排序
      commentPage: 1,
      active: 0,
      num: {
        replay: '',
        article: '',
        video: '',
        course: '',
      },
      isReply: false,
      is_admin_middleground: 'false', // 管理员
    };
  },
  created() {
    this.is_admin_middleground = localStorage.getItem('is_admin_middleground');
    this.queryFun();
    this.getList();
    this.getNum();
  },
  methods: {
    getNum() {
      let allurl = this.$api.commentsCategoryStatistics;
      this.$http.get(allurl, true).then((res) => {
        if (res.data.success) {
          this.num = res.data.data;
          this.teamPlate_status = true;
        }
      });
    },
    //评论切换
    activeItem(item, index) {
      this.active = index;
      this.commentPage = 1;
      this.comment(item.uuid);
      if (this.activeName === 'four') {
        this.isReply = true;
      } else {
        if (item.content_status === 3) {
          this.isReply = false;
        } else {
          this.isReply = true;
        }
      }
    },
    //标签页切换
    handleClick(tab) {
      this.keywords = '';
      this.active = 0;
      this.status = '';
      this.page = 1;
      this.commentPage = 1;
      this.creationData.meta = {
        total: '',
      };
      if (tab.index === '0') {
        // this.type = '1';
        this.type = '3';
        this.commentType = 'article';
      } else if (tab.index === '1') {
        // this.type = '2';
        this.type = '4';
        this.commentType = 'video';
      } else if (tab.index === '2') {
        this.type = '3';
        this.commentType = 'course';
      } else if (tab.index === '3') {
        this.type = '4';
        this.commentType = 'replay';
      }
      this.linkFun();
      this.getList();
    },
    // 改变路由参数
    linkFun() {
      this.$router.push({
        path: '/evaluate',
        query: {
          options: this.options,
          keywords: this.keywords,
          status: this.status,
          page: this.page,
          pageSize: this.pageSize,
          type: this.type,
        },
      });
    },
    // 参数赋值
    queryFun() {
      let query = this.$route.query;
      if (query) {
        if (query.options) {
          this.options = query.options;
        }
        if (query.keywords) {
          this.keywords = query.keywords;
        }
        if (query.type) {
          this.type = query.type;
          if (query.type == '1') {
            this.activeName = 'first';
          } else if (query.type == '2') {
            this.activeName = 'two';
          } else if (query.type == '3') {
            this.activeName = 'three';
          } else if (query.type == '4') {
            this.activeName = 'four';
          }
        }
        if (query.status) {
          this.status = query.status;
        }
        if (query.page) {
          this.page = parseInt(query.page);
        }
        if (query.pageSize) {
          this.pageSize = parseInt(query.pageSize);
        }
      }
    },
    // 列表
    getList() {
      console.log(this.activeName, '===================================');
      this.loading = true;
      if (this.activeName === 'first' || this.activeName === 'two') {
        let url = '';
        // if (this.is_admin_middleground === 'true') {
        url = this.$api.DiscoverContents + `?page=${this.page}&pageSize=${this.pageSize}&options=title&keywords=${this.keywords}&type=${this.type}&status=1`;
        // } else {
        //   url = this.$api.DiscoverContents + `?page=${this.page}&pageSize=${this.pageSize}&options=title&keywords=${this.keywords}&type=${this.type}&status=1`;
        // }

        this.$http.get(url, true).then((res) => {
          if (res.data.success) {
            this.creationData = res.data.data;
            if (this.creationData.data.length) {
              this.activeItem(this.creationData.data[0], 0);
            } else {
              this.comments = [];
            }
          }
          setTimeout(() => {
            this.loading = false;
          }, 800);
        });
      } else if (this.activeName === 'three') {
        let url = this.$api.Courses + `?page=${this.page}&pageSize=${this.pageSize}&options=title&keywords=${this.keywords}`;
        this.$http.get(url, true).then((res) => {
          if (res.data.success) {
            this.creationData = res.data.data;
            if (this.creationData.data.length) {
              this.activeItem(this.creationData.data[0], 0);
            } else {
              this.comments = [];
            }
          }
          setTimeout(() => {
            this.loading = false;
          }, 800);
        });
      } else if (this.activeName === 'four') {
        let url = this.$api.lives + this.$api.liveVideos + `?page=${this.page}&pageSize=${this.pageSize}&options=title&keywords=${this.keywords}`;
        this.$http.get(url, true).then((res) => {
          if (res.data.success) {
            this.creationData = res.data.data;
            if (this.creationData.data.length) {
              this.activeItem(this.creationData.data[0], 0);
            } else {
              this.comments = [];
            }
          }
          setTimeout(() => {
            this.loading = false;
          }, 800);
        });
      }
      setTimeout(() => {
        this.loading = false;
      }, 800);
    },
    // 分页数据
    handleCurrentChange(val) {
      this.page = val;
      this.linkFun();
      this.getList();
    },
    // 搜索查询
    screenSubmit() {
      this.page = 1;
      this.linkFun();
      this.getList();
    },
    // 评价更新
    commentsChildren(data) {
      if (data === 'upload') {
        this.linkFun();
        this.getList();
      }
    },
    //获取评论列表
    comment(id) {
      let ctype = '';
      if (this.type === '1') {
        ctype = 'article';
      } else if (this.type === '2') {
        ctype = 'video';
      } else if (this.type === '3') {
        ctype = 'course';
      } else if (this.type === '4') {
        ctype = 'replay';
      }
      this.uuid = id;
      this.commentType = ctype;
      let url = this.$api.comments + `?type=${ctype}&uuid=${id}&status=${this.status}&sort=${this.sort}&page=${this.commentPage}&pageSize=30`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.comments = res.data.data;
        }
      });
    },
    //加载更多
    commentMore() {
      this.commentPage += 1;
      let url = this.$api.comments + `?type=${this.commentType}&uuid=${this.uuid}&page=${this.commentPage}&sort=${this.sort}&status=${this.status}&pageSize=30`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.comments.data.push(...res.data.data.data);
          this.comments.meta = res.data.data.meta;
        }
      });
    },
    //评论分类
    commentQuery() {
      this.comment(this.uuid);
    },
    // 重置
    resetFun() {
      this.status = '';
      this.sort = 1;
      this.commentQuery();
    },
    dialog(command) {
      if (command === 'del') {
        this.isdelDialogVisible = true;
      } else if (command === 'reply') {
        this.isDialogVisible = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/evaluate/index.less';
</style>
